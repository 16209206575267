<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{}">
        <div class="d-flex  flex-row scheduleButtons">
          <v-btn
            color="filterButtonColor"
            dark
            @click="
              dialog = true;
              tab = 0;
            "
          >
            <v-icon> mdi-calendar-text-outline</v-icon>
            <font v-show="$vuetify.breakpoint.smAndUp">Schedule</font>
          </v-btn>
          <v-btn
            color="filterButtonColor"
            dark
            @click="
              dialog = true;
              tab = 1;
            "
          >
            <v-icon> mdi-account-group</v-icon>
            <font v-show="$vuetify.breakpoint.smAndUp">Speakers</font>
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-toolbar class="ScheduleListMenu" color="menuBarColor" dark>
          <v-toolbar-items
            v-show="false && $vuetify.breakpoint.xsOnly"
            class="ma-0 pa-0"
          >
            <v-btn x-small @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-spacer> </v-spacer>

          <div>
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab v-for="item in items" :key="item.title">
                <v-icon class="mr-1">{{ item.icon }}</v-icon>
                {{
                  $vuetify.breakpoint.smAndDown ? item.title : item.titleLong
                }}
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer> </v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div>
              <ScheduleList />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div><SpeakersList /></div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "../../main";

import ScheduleList from "../conference/ScheduleList.vue";
import SpeakersList from "../conference/SpeakersList.vue";
export default {
  name: "ConferenceScheduleDialog",
  components: {
    ScheduleList,
    SpeakersList
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          title: "Schedule",
          titleLong: "Conference Schedule",
          icon: "mdi-calendar-text-outline"
        },
        {
          title: "Speakers",
          titleLong: "Conference Speakers",
          icon: "mdi-account-group"
        }
      ],
      tab: 0
    };
  },
  mounted() {
    eventBus.$on("nav_schedule_setOpenState", value => {
      this.dialog = value ? true : false;
    });
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 959px) {
  .ScheduleListMenu {
    .v-toolbar__content {
      padding: 0;
    }

    i.mdi {
      font-size: 18px !important;
    }

    .v-tab {
      padding: 0 8px;
      font-size: 11px;
    }
  }
  .scheduleButtons button {
    padding: 0 !important;
    min-width: 38px !important;
  }
}
</style>
