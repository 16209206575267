<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.xsOnly ? '95%' : 550"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          class=" "
          @click="scrollToSiteDelay()"
          v-if="false"
        >
          FAQ
        </span>
      </template>

      <v-card>
        <v-card-title
          class="dialogTitleBar  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          Help
        </v-card-title>

        <v-row no-gutters class="secondary--text  " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper bodyText"
            id="HelpActWrapper"
            @scroll="onScroll"
          >
            <center id="topOfHelpSection">
              <v-card-title id="helpHeading_nav" class="justify-center"
                >How to use the map
              </v-card-title>

              <img
                class="helpImage"
                src="/assets/help/main.jpg"
                style="max-width:100%"
              />

              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title class="justify-center" style="font-size: 1.6rem;"
                >FAQ
              </v-card-title>
              <v-card-title class="justify-center"
                >How do I find my location?
              </v-card-title>

              <v-card-text>
                Tap the location button on the left-hand side. If prompted, you
                may need to allow location services on your phone. This
                information is <u>not</u> collected nor retained by VPELA or
                Maptivate.
              </v-card-text>
              <img class="helpImage" src="/assets/help/location.jpg" />

              <br />
              <br />
              <v-divider></v-divider>

              <v-card-title class="justify-center"
                >How do I find the conference schedule and speaker information?
              </v-card-title>

              <v-card-text>
                Click on the buttons at the top of the screen
              </v-card-text>

              <img class="helpImage" src="/assets/help/schedule.jpg" />

              <br />
              <br />
              <v-divider></v-divider>

              <v-card-title class="justify-center"
                >How do I find a location on the map?
              </v-card-title>

              <v-card-text>
                From the program schedule page click on “Go to Location” button
              </v-card-text>
              <img class="helpImage" src="/assets/help/mapFlyTo.jpg" />
              <v-card-text
                >If you know the name of the location, type it into the search
                box at the top of the site list. Click on the location in the
                list.
              </v-card-text>
              <img class="helpImage" src="/assets/help/list.jpg" />

              <br />
              <br />
              <v-divider></v-divider>

              <v-card-title class="justify-center"
                >How do I navigate around the map on my phone?
              </v-card-title>

              <v-card-text>
                Use two fingers to pinch and zoom in and out.<br />
                Use one finger to navigate around the map.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>

              <v-card-title class="justify-center"
                >How do I translate the map into another language?
              </v-card-title>

              <v-card-text>
                Click on the translation button in the top left of the screen to
                see a list of languages to choose from.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>

              <v-card-title class="justify-center"
                >What do the symbols on the map mean and how can I view the map
                legend?
              </v-card-title>

              <v-card-text>
                Click on the map symbol in the bottom right of the screen to
                bring up the legend.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
            </center>
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              :color="appSettings.dialogActionButtonColor"
              v-show="!atTop"
              class="v-fade"
              text
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "HelpModal",
  components: {},
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Help"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadHelpDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      console.log("onScroll!!");
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `topOfHelpSection`;

      if (newValue) {
        element = `helpHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("HelpActWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}

.bodyText {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}
</style>
