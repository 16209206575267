<template>
  <center>
    <div class="mt-5">
      <div>
        <div
          class="d-flex  flex-wrap justify-center space-around"
          id="ConferenceSpeakersList"
        >
          <template v-for="speaker in speakersFormated">
            <v-card
              class="ma-2 pt-3 speakerCard"
              :key="`speakerObj_${speaker.postId}`"
            >
              <v-avatar size="120">
                <img :src="speaker.featured_thumb" :alt="speaker.title" />
              </v-avatar>

              <v-card-title
                class="justify-center text-subtitle-1 font-weight-bold"
                >{{ speaker.title }}</v-card-title
              >
              <v-card-subtitle class="font-italic">{{
                speaker.qualifications
              }}</v-card-subtitle>
              <v-card-subtitle class="font-italic">{{
                speaker.organization
              }}</v-card-subtitle>

              <extraLinkBlocks
                :appSettings="appSettings"
                :properties="speaker"
                :Point_Name="speaker.Point_Name"
                :Site_Marker_Number="speaker.Site_Marker_Number"
                :structuredData="speaker.structuredData"
                :isJustSubtitles="false"
                :isJustLinks="true"
                :isPopup="false"
                :eventsFormated="null"
                :postId="speaker.post_id"
              />
              <div
                class="text-container"
                v-if="showPeopleList[speaker.postId] && speaker.short_overview"
              >
                <v-divider> </v-divider>

                <v-card-text>
                  <div v-html="speaker.short_overview"></div>
                </v-card-text>
              </div>

              <v-btn
                v-if="speaker.short_overview"
                color="white"
                raised
                rounded
                x-small
                class="font-weight-bold mb-2"
                @click="showHide(speaker.postId)"
              >
                {{ showPeopleList[speaker.postId] ? "hide bio" : "show bio" }}
              </v-btn>

              <div v-if="isDevEnviroment">
                <v-btn
                  @click="editWpItem(speaker.postId)"
                  depressed
                  class="ma-2"
                >
                  <v-icon left>mdi-pen</v-icon>
                  Cust WP Edit
                </v-btn>
              </div>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </center>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
import extraLinkBlocks from "./../info/extraLinkBlocks.vue";

export default {
  name: "ConferenceSpakersList",

  components: {
    extraLinkBlocks
  },

  data() {
    return { showDescriptions: true, showPeopleList: {} };
  },

  methods: {
    editWpItem(post_id) {
      //console.log("editWpItem", { post_id: properties.post_id });

      //let route = this.$router.resolve({ path: "/contact" });
      if (post_id) {
        window.open(
          `https://cms01.maptivateapp.com.au/vpelaconference2024lorne/wp-admin/post.php?post=${post_id}&action=edit`
        );
      }
    },

    showHide(postId) {
      if (this.showPeopleList[postId]) {
        this.showPeopleList[postId] = false;
      } else {
        this.showPeopleList[postId] = true;
      }

      this.showPeopleList = JSON.parse(JSON.stringify(this.showPeopleList));
    },
    loadMarker(index) {
      if (!isNaN(index)) {
        eventBus.$emit("nav_speakers_setOpenState", false);

        setTimeout(() => {
          eventBus.$emit("nav_map_flyToOpenPopUp", index);
        }, 700);
      }
    }
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      isDevEnviroment: state => state.navigation.isDevEnviroment,

      //markerList: state => state.markers.markerList,
      speakersFormated: state => state.markers.speakersFormated
    })
  }
};
</script>

<style scoped>
#ConferenceSpeakersList {
  width: 900px;
  max-width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  border: 1px solid #d7d7d7;
}

.speakerCard {
  width: 270px;
  min-height: 300px;
}

@media only screen and (max-width: 1263px) {
  #ConferenceSpeakersList {
    width: 100%;
    max-width: 100%;
  }

  .speakerCard {
    width: 186px;
    min-height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .v-card__title,
  .v-card__subtitle {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
@media only screen and (max-width: 400px) {
  .speakerCard {
    width: 166px;
  }
}

@media only screen and (max-width: 403px) {
  .speakerCard {
    width: 156px;
  }
}
</style>
