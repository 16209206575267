<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '98%' : 450"
    >
      <template v-slot:activator="{ on, attrs }" v-show="showButton">
        <span
          v-show="showButton"
          v-bind="attrs"
          v-on="on"
          class="white--text  "
        >
          About
        </span>
      </template>

      <v-card>
        <v-card-title
          class="dialogTitleBar  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          {{ title }}
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-col cols="12" md="12" class="ma-0 pa-0 welcomeText">
              <center>
                <div style="background:#c2d3ed;width:100%" class="pb-1">
                  <img
                    style="overflow:hidden;max-width:70%"
                    src="/assets/landing/top.jpg"
                  />

                  <img
                    style="overflow:hidden;max-width:60%"
                    src="/assets/landing/logo.jpg"
                  />
                </div>
                <ToggleGeoButton
                  v-if="false"
                  :isReady="isReady"
                  :dialog="dialog"
                >
                </ToggleGeoButton>

                <img
                  style="overflow:hidden;max-width:100%"
                  src="/assets/landing/bottom.jpg"
                />
              </center>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadHelp()"
          >
            Help
          </v-btn>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              <font v-if="!$vuetify.breakpoint.smAndDown">Maptivate</font>
            </v-btn>
          </a>

          <v-spacer></v-spacer>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";
import ToggleGeoButton from "../ui/ToggleGeoButton.vue";

export default {
  name: "AboutModal",
  components: {
    ToggleGeoButton
    //intoVideo
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },

  data() {
    return {
      dialog: true,
      title: "Welcome to VPELA Lorne Maptivate"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  methods: {
    loadHelp() {
      eventBus.$emit("loadHelpDialog");
    }
  },
  mounted() {}
};
</script>

<style>
.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .v-dialog > .v-card > .v-card__title.dialogTitleBar {
    font-size: 1.1rem;
  }

  .contentWrapper {
    max-height: calc(100vh - 240px);
  }
}
</style>
