var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isJustSubtitles)?[(
        _vm.isJustLinks === true &&
          _vm.structuredData.links &&
          (_vm.isPopup || _vm.showLinks['links'])
      )?_c('v-card-text',{staticClass:"pt-1 pb-0"},[_vm._l((_vm.structuredData.links),function(link,index){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPopup),expression:"!isPopup"}],key:(_vm.viewType + "_links_p2_" + index),staticClass:"pa-0 ma-0 ",staticStyle:{"line-height":"1.3"}},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(link.url),expression:"link.url"}],staticClass:"primary--text",staticStyle:{"text-decoratin":"underline!important"},attrs:{"href":link.url,"target":"_blank"}},[_vm._v(_vm._s(link.title ? link.title : link.url))])])}),_vm._l((_vm.structuredData.links),function(link,index){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPopup),expression:"isPopup"}],key:(_vm.viewType + "_links_" + index),staticClass:"pa-0 ma-1 "},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(link.url),expression:"link.url"}],staticClass:"primary--text",staticStyle:{"text-decoratin":"underline!important"},attrs:{"href":link.url,"target":"_blank"}},[_vm._v(_vm._s(link.title ? link.title : link.url))])])})],2):_vm._e(),(
        _vm.structuredData.downloadAssets &&
          (!_vm.isPopup || _vm.showLinks['downloadAssets'])
      )?_c('v-card-text',[_c('span',{staticClass:"font-weight-medium   headingText"},[_vm._v(" Documents: ")]),_vm._l((_vm.structuredData.downloadAssets),function(link,index){return _c('p',{key:(_vm.viewType + "_docs_" + index),staticClass:"pa-0 ma-1 "},[_c('a',{staticClass:"primary--text",staticStyle:{"text-decoration":"underline!important"},attrs:{"href":link.url,"target":"_blank"}},[_vm._v(" "+_vm._s(link.title)+" ")])])})],2):_vm._e(),(
        _vm.showEvent &&
          _vm.postId &&
          !_vm.isJustSubtitles &&
          !_vm.isPopup &&
          _vm.eventsFormated &&
          _vm.postId &&
          _vm.eventsFormated[_vm.postId]
      )?_c('v-card-text',[(_vm.postId)?_c('eventTimeline',{ref:"timelineUi",attrs:{"eventsFormated":_vm.eventsFormated,"postId":_vm.postId}}):_vm._e()],1):_vm._e(),(
        _vm.isJustLinks === true &&
          _vm.structuredData.socialmedia &&
          (!_vm.isPopup || _vm.showLinks['socialmedia'])
      )?_c('v-card-text',{staticClass:"pt-1 pb-1"},[(false)?_c('span',{staticClass:"text-h5 text--darken-1 "},[_vm._v("Social media: ")]):_vm._e(),_c('div',_vm._l((_vm.structuredData.socialmedia),function(link,index){return _c('v-btn',{key:(_vm.viewType + "_socialmedia_" + index),staticClass:"mx-0 px-0",attrs:{"small":"","fab":"","elevation":"0","href":link.url,"title":((link.title) + ":  " + (link.url)),"target":"_blank","color":"white"}},[_c('v-icon',{staticClass:"mx-0 px-0",attrs:{"xcolor":_vm.appSettings.socialIcon[link.title].color,"color":"#394049"}},[_vm._v(" "+_vm._s(_vm.appSettings.socialIcon[link.title].icon))])],1)}),1),(false)?_c('div',_vm._l((_vm.structuredData.socialmedia),function(link,index){return _c('v-btn',{key:(_vm.viewType + "_socialmedia_" + index),staticClass:"mx-1",attrs:{"light":"","fab":"","small":"","href":link.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":_vm.appSettings.socialIcon[link.title].color}},[_vm._v(_vm._s(_vm.appSettings.socialIcon[link.title].icon))])],1)}),1):_vm._e()]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }