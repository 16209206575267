var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{staticClass:"pb-1",attrs:{"elevation":"6","x_shaped":""}},[(_vm.properties.media && _vm.properties.media.length)?_c('v-carousel',{attrs:{"eager":"","hide-delimiters":"","show-arrows":_vm.properties.media.length > 1,"height":'150px'}},_vm._l((_vm.properties.media),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.src_thumb}})}),1):_vm._e(),_c('center',{staticClass:"headingBlock pt-3 px-2"},[_c('div',{staticClass:"secondary--text text--darken-1"},[(_vm.properties.Point_Name)?_c('div',{staticClass:"mainHeading  "},[_c('engAndTrans',{attrs:{"value":_vm.properties.Point_Name}})],1):_vm._e()]),(
        _vm.properties.structuredData &&
          _vm.properties.structuredData.subtitle &&
          _vm.properties.structuredData.subtitle.M_ST_Address_Title &&
          _vm.properties.structuredData.subtitle.M_ST_Address_Title.value
      )?_c('div',{staticClass:"notranslate subHeading",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.properties.structuredData.subtitle.M_ST_Address_Title.value)+" ")]):_vm._e()]),_c('PointDescriptionBody',{attrs:{"functions":{ updateShowMore: _vm.updateShowMore, openSidebarRemote: _vm.openSidebarRemote },"type":"popup","Point_Description":_vm.Point_Description,"showMore":_vm.showMore}}),_c('extraInfoBlocks',{attrs:{"appSettings":_vm.appSettings,"location":_vm.properties,"media":_vm.properties.media,"Point_Name":_vm.properties.Point_Name,"Site_Marker_Number":_vm.properties.Site_Marker_Number,"structuredData":_vm.properties.structuredData,"functions":{ openYoutube: _vm.openYoutube, setSound: _vm.setSound, getAudioDetails: _vm.getAudioDetails, pointSet: _vm.pointSet },"isPopup":true}}),(_vm.$vuetify.breakpoint.mdAndDown || !_vm.appSettings.autoOpenMoreInfo)?_c('v-card-actions',{staticClass:"pb-0 mb-0 pt-0 mt-0 popupActionButtons"},[(false)?_c('v-btn',{staticClass:"moreInfoButton pb-0 mb-0 pt-0 mt-0 ",attrs:{"flat":"","color":"menuBarColor","width":_vm.appSettings.popupButtonWidth},on:{"click":function($event){return _vm.openSidebar(_vm.properties.index)}}},[_vm._v(" More Info / Schedule ")]):_vm._e(),_c('maptivateInfoButton',{attrs:{"width":_vm.appSettings.popupButtonWidth,"color":"filterButtonColor","title":"More Info / Schedule","clickfunction":function () {
          _vm.openSidebar(_vm.properties.index);
        }}}),_c('v-spacer'),(false)?_c('v-btn',{attrs:{"right":"","color":"dialogActionButtonColor","text":""},on:{"click":_vm.clearAllPopups}},[_vm._v(" Close ")]):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }