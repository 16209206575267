<template>
  <div class="text-center">
    <v-dialog v-model="openIntro">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font>
            TableDataView
          </font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          TableDataView
        </v-card-title>

        <div class="fullHeight fullHeightMax">
          <allMapItemsTable
            :itemsPerPage="itemsPerPage"
            :itemPerPageOption="itemPerPageOption"
          ></allMapItemsTable>
        </div>
        <v-card-actions>
          <v-btn
            color="dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import allMapItemsTable from "./revewTable/allMapItemsTable";

export default {
  name: "TableDataView",
  components: { allMapItemsTable },
  data() {
    return {
      itemsPerPage: 10,
      itemPerPageOption: [5, 10, 25, -1],

      openIntro: false,
      markerSearch: "",
      iconSize: 40
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerSections: state => state.markers.sections,
      geoJson_artwork: state => state.markers.geoJson_artwork,
      geoJson_signage: state => state.markers.geoJson_signage,
      categoryLookup: state => state.markers.categoryLookup,
      markerImages: state => state.markers.markerImages,
      markerList: state => state.markers.markerList,
      layers: state => state.filters.layers
    }),
    markerListData() {
      return this.markerList.data.features.map(row => {
        return {
          ...row.properties,
          attachedMediaCount: row?.properties?.media?.length
            ? row?.properties?.media?.length
            : 0,

          structuredDataCount: row?.properties?.structuredData
            ? _.size(row?.properties?.structuredData)
            : 0,

          coordinates: row?.geometry?.coordinates,

          hasCoordinates:
            !isNaN(row?.properties?.Longitude) &&
            !isNaN(row?.properties?.Latitude)
              ? "yes"
              : "no"
        };
      });
    }
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped>
.fullHeight {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 160px);
}

.fullHeightMax {
  max-height: calc(100vh - 160px);
}
</style>
