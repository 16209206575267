var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mapWrapper"},[(_vm.isDevEnviroment)?_c('div',{attrs:{"id":"currentZoomDev"}},[_vm._v(" "+_vm._s(_vm.currentZoom)+" ")]):_vm._e(),_c('mapbox',{attrs:{"access-token":_vm.appSettings.map.accessToken,"map-options":Object.assign({}, _vm.options, _vm.appSettings.map.options),"nav-control":{
      show: true,
      position: 'top-left'
    },"geolocate-control":{
      show: true,
      position: 'top-left',
      options: {
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }
    },"scale-control":{
      show: false,
      position: 'top-left'
    },"fullscreen-control":{
      show: true,
      position: 'top-left'
    }},on:{"map-load":_vm.loaded,"map-init":_vm.initialized}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }