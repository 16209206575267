var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('center',[_c('div',{staticClass:"mt-5"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-switch',{attrs:{"label":"Show Descriptions"},model:{value:(_vm.showDescriptions),callback:function ($$v) {_vm.showDescriptions=$$v},expression:"showDescriptions"}})],1),_c('div',{staticStyle:{"width":"900px","max-width":"100%"},attrs:{"fluid":"","id":"ConferenceScheduleList"}},[_vm._l((_vm.eventTimeKeys),function(timeKey,timeIndex){return [_c('div',{key:("timeRow_" + timeKey)},[_c('v-card',{staticClass:"ma-0 eventTime",attrs:{"outlined":"","tile":"","color":"eventHeaderBack"}},[_c('div',{staticClass:"eventTimeTitle  pa-2"},[_vm._v(" "+_vm._s(_vm.eventTimeKeysFormated[timeIndex])+" ")])]),_c('div',[_c('v-container',{staticClass:"eventListBack mb-0 pb-2"},[_c('v-row',{staticClass:"mb-0 pb-0",attrs:{"no-gutters":""}},_vm._l((_vm.eventsByTime[timeKey]),function(event){return _c('v-col',{key:event.postId,staticClass:"mb-2",attrs:{"cols":"12","md":_vm.eventTimeKeysWidths[timeIndex]}},[_c('v-card',{staticClass:"pa-2 pt-0 mx-1 eventItem",attrs:{"outlined":"","tile":"","color":"eventItemBack"}},[_c('div',{staticClass:"eventTitle menuBarColor--text",domProps:{"innerHTML":_vm._s(event.title)}}),(
                          event.linkedMarkerId &&
                            _vm.sitesLookup[event.linkedMarkerId] &&
                            _vm.sitesLookup[event.linkedMarkerId].Point_Name
                        )?_c('a',{on:{"click":function($event){return _vm.loadMarker(_vm.sitesLookup[event.linkedMarkerId].index)}}}):_vm._e(),_c('div',{staticClass:"d-flex  flex-column align-startx\n"},[(
                            !(
                              event.linkedMarkerId &&
                              _vm.sitesLookup[event.linkedMarkerId] &&
                              _vm.sitesLookup[event.linkedMarkerId].Point_Name
                            )
                          )?_c('div',{staticClass:"eventValue"},[_vm._v(" no location "+_vm._s(event.linkedMarkerId)+" ")]):_vm._e(),(
                            event.linkedMarkerId &&
                              _vm.sitesLookup[event.linkedMarkerId] &&
                              _vm.sitesLookup[event.linkedMarkerId].Point_Name
                          )?_c('div',{staticClass:"eventValue"},[_c('font',{staticClass:"subHeadingItem"},[_vm._v("location: ")]),_vm._v(_vm._s(_vm.sitesLookup[event.linkedMarkerId].Point_Name)+" ")],1):_vm._e(),_c('EventPeopleSection',{attrs:{"event":event}}),(event.endTimeFormated)?_c('div',{staticClass:"eventValue"},[_c('font',{staticClass:"subHeadingItem"},[_vm._v("end: ")]),_vm._v(_vm._s(event.endTimeFormated)+" ")],1):_vm._e(),(
                            event.linkedMarkerId &&
                              _vm.sitesLookup[event.linkedMarkerId] &&
                              _vm.sitesLookup[event.linkedMarkerId].Point_Name
                          )?_c('a',{on:{"click":function($event){return _vm.loadMarker(_vm.sitesLookup[event.linkedMarkerId].index)}}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"white","raised":"","rounded":"","x-small":""}},[_vm._v(" Go To Location ")])],1):_vm._e()],1),(_vm.showDescriptions && event.description)?_c('div',{staticClass:"eventValue eventDescription pt-2"},[_c('font',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"subHeadingItem"},[_vm._v("Description: ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(event.description),expression:"event.description"}],staticStyle:{"color":"rgb(87, 87, 87)","margin-bottom":"10px","padding-bottom":"10px","xborder-bottom":"1px solid grey"},domProps:{"innerHTML":_vm._s(event.description)}})],1):_vm._e(),(_vm.isDevEnviroment)?_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":""},on:{"click":function($event){return _vm.editWpItem(event.postId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pen")]),_vm._v(" Cust WP Edit ")],1)],1):_vm._e()])],1)}),1)],1)],1)],1)]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }