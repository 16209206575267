// import Vue from 'vue'

const getters = {};
const state = {
  currentCountryNotEnglish: false,
  translationLanguageCode: "en|en",
  translationLanguageTitle: "English"
};

const mutations = {
  UPDATE_LANGUAGE(state, newValue) {
    let {
      currentCountryNotEnglish,
      translationLanguageCode,
      translationLanguageTitle
    } = newValue;
    state.currentCountryNotEnglish = currentCountryNotEnglish;
    state.translationLanguageCode = translationLanguageCode;
    state.translationLanguageTitle = translationLanguageTitle;
  }
};
const actions = {
  translationUpdateLanguage({ commit }, values) {
    commit("UPDATE_LANGUAGE", values);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
