<template>
  <div class="">
    <v-dialog v-model="dialog" persistent max-width="640">
      <template v-slot:activator="{}">
        <maptivateInfoButton
          :Site_Marker_Number="Site_Marker_Number"
          :isPopup="isPopup"
          butType="sphereOpen"
          :width="width"
          :color="appSettings.mediaButtonColor"
          icon="mdi-camera"
          :title="title"
          :clickfunction="
            () => {
              dialog = true;
            }
          "
        />
      </template>

      <v-card>
        <v-card-title
          v-if="false"
          :style="{
            background: appSettings.menuBarColor
          }"
          :class="
            appSettings.headingTextColor + ' headline  hidden-sm-and-down'
          "
        >
          {{ youTubeTitle }}
        </v-card-title>

        <div
          class="streetViewDialog"
          ref="box"
          v-if="googleStreetIframe && url"
        >
          <iframe
            :src="url"
            width="100%"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row
            no-gutters
            class="contentWrapper  secondary--text "
            style=""
          ></v-row>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dialogActionButtonColor" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "PhotoSphere",
  components: {
    maptivateInfoButton
  },
  props: {
    width: String,
    googleStreetIframe: String,
    appSettings: Object,
    Site_Marker_Number: String,

    isPopup: Boolean
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    url() {
      try {
        if (this.googleStreetIframe && this.googleStreetIframe.includes("||")) {
          return this.googleStreetIframe.split("||")[0].trim();
        } else {
          return this.googleStreetIframe;
        }
      } catch (error) {
        return null;
      }
    },
    title() {
      try {
        if (this.googleStreetIframe && this.googleStreetIframe.includes("||")) {
          return this.googleStreetIframe.split("||")[1].trim();
        } else {
          return "Photo Sphere";
        }
      } catch (error) {
        return "Photo Sphere";
      }
    }
  }
};
</script>

<style>
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
@media only screen and (max-width: 960px) {
  .contentWrapper {
    max-height: calc(100vh - 280px);
  }
}
</style>
