<template>
  <v-app
    :class="{
      currentCountryNotEnglish: currentCountryNotEnglish,
      'my-app': true,
      isApple: isIOS,
      isComputer: !$vuetify.breakpoint.mdAndDown,
      isTabletOrComputer: !$vuetify.breakpoint.smAndDown,
      isSmallComputer: !$vuetify.breakpoint.smAndDown,
      isSmallPcOrTabletOrMobile: $vuetify.breakpoint.mdAndDown,
      isTabletOrMobile: $vuetify.breakpoint.mdAndDown,
      isTablet: $vuetify.breakpoint.mdOnly
    }"
  >
    <Layout v-if="ready" />

    <AboutModal :isReady="ready" :showButton="false" v-if="true" class="mr-8" />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";
import { mapState } from "vuex";

import AboutModal from "./components/info/AboutModal.vue";

export default {
  name: "App",
  async mounted() {
    this.$store.dispatch("wpData_initData", { vm: this });
  },
  components: {
    Layout,
    AboutModal
  },

  data: () => ({
    ready: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      wpdata: state => state.wpdata.wpdata,
      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    wpdata: function(newValue) {
      if (newValue?.categoryOrder.length) {
        this.$store.dispatch("global_updateMaptivateSetupObject", newValue);
        this.$store.dispatch("markers_updateData", newValue);
        this.$store.dispatch("filters_updateData", newValue);

        this.ready = true;
      }
    }
  },

  created() {
    window.mvAnalyticEvent = function(category, action, label, value) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      //window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    window.addEventListener("orientationchange", documentHeight);
    screen.orientation.addEventListener("change", documentHeight);

    documentHeight();
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  touch-action: manipulation;
}

.v-application .mapControlButtonsFloating button.filterLegendButtonColor {
  border: 1px solid #95b4dc9e !important;
}
</style>
