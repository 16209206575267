<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 600"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          ><font v-if="!$vuetify.breakpoint.xsOnly">
            Acknowledgement of Country
          </font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          class="dialogTitleBar  xlighten-2 white--text text-center justify-center siteHeadingText"
        >
          Acknowledgement of Country
        </v-card-title>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row class="contentWrapper noOverflowOnWide">
            <v-col
              cols="6"
              style="overflow:hidden;max-height:100%"
              class="pa-3"
              v-if="false && $vuetify.breakpoint.smAndUp"
            >
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                class="white--text align-end  ma-0"
                src="/assets/landingPages/acknowledgement.jpeg"
              >
              </v-img>
            </v-col>

            <v-col
              cols="12"
              style="overflow:hidden;max-height:100%;xmargin-bottom:-50px"
              v-if="false && $vuetify.breakpoint.smAndDown"
            >
              <center>
                <v-img
                  class="white--text align-end ma-0"
                  style="overflow:hidden;max-width:30%"
                  src="/assets/landingPages/acknowledgement.jpeg"
                >
                </v-img>
              </center>
            </v-col>

            <v-col cols="12" class="mb-0 pb-0 bodyTextAck" style="">
              <v-card-text>
                <p class="px-4">
                  We acknowledge and respect the separate and distinct Eastern
                  Maar and Wadawurrung Peoples as the Traditional Owners of the
                  Great Ocean Road’s land, waters, seas and skies and
                  acknowledge their cultural knowledge that has led to
                  sustainable practices and has cared for Country over tens of
                  thousands of years. We honour Elders past and present and
                  express gratitude for their sharing of wisdom that has ensured
                  the continuation of Culture and Traditional practices.
                </p>
              </v-card-text>
            </v-col>
            <v-col
              md="12"
              cols="12"
              class="ma-0 pa-0 bodyTextAck"
              style=""
              v-if="false"
            >
              <v-card-text>
                <center>
                  <p>
                    Place holder
                  </p>
                </center>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn color="dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            color="dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            color="dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }
      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  created() {
    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  mounted() {
    setTimeout(() => {
      this.openIntro = true;
    }, 300);
  }
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .noOverflowOnWide {
    overflow: hidden;
  }

  .v-application div.bodyTextAck {
    margin-bottom: -18px !important;
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 959px) {
  .v-application div.bodyTextAck {
    padding-top: 0 !important;
  }

  .noOverflowOnWide {
    margin: 0 !important;
  }
}
</style>
