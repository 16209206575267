import _ from "lodash";
import { mapState } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings
    })
  },

  methods: {
    updatefilters(filters) {
      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        let thisSourceData = this.map.getSource(source)._data;
        thisSourceData.features.forEach(feature => {
          if (filters.includes(feature.properties.section_slug)) {
            feature.properties.hide = false;
            feature.geometry.coordinates = [
              feature.properties.Latitude,
              feature.properties.Longitude
            ];
          } else {
            feature.properties.hide = false;
            feature.geometry.coordinates = [0, 0];
          }
        });

        this.map.getSource(source).setData(thisSourceData);
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
