const wpPull = require("./wpPull");
const cachePull = require("./cachePull");

var useCache = false;
var useS3Cache = true; //if using cache, use this address;
var useS3Address = `https://maptivate-cms-01.s3.ap-southeast-2.amazonaws.com/sites01/4/cacheData.json`;

//using window.mvSource={mvs:"w",mvc:'s'}; for configs;

/*
in header add:
window.mvSource = {
  mvs: "c", // maptivate source, w = wordpress live, c = cache;
  mvc: "s", // maptivate cache location if used, s = s3, l = local/baked in   ;
  mvsl: "sites01/4" // maptivate s3 cache location 'sites01/4';
};

*/

//window.mvSource = null;

if (window.mvSource) {
  if (window.mvSource.mvs && window.mvSource.mvs == "c") {
    //use cache;
    useCache = true;
  }
  if (window.mvSource.mvc && window.mvSource.mvc == "l") {
    //use s3 as the cache;
    useS3Cache = false;
  }
  if (window.mvSource.mvsl && window.mvSource.mvsl.length) {
    //speficy project address in S3.
    useS3Address = `https://maptivate-cms-01.s3.ap-southeast-2.amazonaws.com/${window.mvSource.mvsl}/cacheData.json`;
  }
}

const state = {
  post: {},
  taxonomy: {},
  wpdata: {}
};

const getters = {};
const actions = {
  async wpData_initData({ commit } /*, { vm }*/) {
    let output;

    if (useCache === true) {
      output = await cachePull.getData({ useS3: useS3Cache, useS3Address });
    } else {
      output = await wpPull.getData();
    }

    /**
 * 
     output = {
      maptivateSetupObject,
      stackedMarkers,
      sections: sectionObj,
      markerImages,
      ...geoJsonByType,
      categoryLookup,
      categoryOrder: setTaxonomySetOrder,
      listCategoryLookup
    };

 * 
 */

    const filterOutHHW = true;
    if (filterOutHHW === true) {
      for (const key in output.sections) {
        if (key.includes("_hhw")) {
          delete output.sections[key];
        }
      }
      for (const key in output.categoryLookup) {
        if (key.includes("_hhw")) {
          delete output.categoryLookup[key];
        }
      }
      output.geoJson_main_marker.features = output.geoJson_main_marker.features.filter(
        row => !row.properties.section_slug.includes("_hhw")
      );
    }

    commit("SET_WP_DATA", output);
  }
};

const mutations = {
  SET_WP_DATA(state, newValue) {
    state.wpdata = newValue;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
