import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#1f3531",
        secondary: "#1f3531",
        accent: "#536d9d", //link text
        error: "#1f3531", //item se
        ////secondary: "#394246lected
        //info: "#fcfbf1",//
        //success: "#00F",
        //warning: "#00F"
        warning: "#fcfbf1", //marker text,

        header: "#a3bba0",
        tabs: "#96a992",

        mediaButtonColor: "#95B4DC",
        //mediaButtonColor: "#BDD4EC",

        filterLegendButtonColor: "#001a5d",

        lightbuttonColor: "#BDD4EC",

        dialogActionButtonColor: "black",
        logoTextColor: "#95B4DC",
        filterButtonColor: "#95B4DC",

        mainMenuBarColor: "#001a5d",
        menuBarColor: "#213a7c",

        dialogTitleBar: "#40568e",

        eventHeaderBack: "#95B4DC",
        eventItemBack: "#cad9ec",
        eventListBack: "#dde7f3"
      }
    }
  }
});
