const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};

const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoiaW5mby1tYXB0aXZhdGUiLCJhIjoiY2xmbTF1Ym9wMDdmczQxbW9vcmdmYm92cCJ9.UgrbRYg9Chk1JJzNvgnQNw",
      options: {
        //style: "mapbox://styles/mapbox/standard",
        style: "mapbox://styles/info-maptivate/clzgomist00jj01r147uf3pov",
        //center: [143.9738, -38.5369],
        center: [143.99896957669455, -38.50948668770722],
        maxZoom: 22,
        minZoom: 5,
        pitch: 0,
        bearing: 10,
        zoom: 10
      },
      bounds: [
        [95.02531113711069, -48.38694285439368], // Southwest coordinates
        [162.91836982955203, -20.238778736219153] // Northeast coordinates
      ],
      defaultSetup: {
        center: [143.9738, -38.5369],
        zoom: 17,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 30,
        bearing: 0
      },
      flyToBounds: [
        [143.9641681700486, -38.54974724837939], // Southwest coordinates
        [143.9888088169908, -38.52337666567215] // Northeast coordinates
      ],
      disableRotationAndBearing: false
    },

    wpEditSite:
      "https://cms01.maptivateapp.com.au/vpelaconference2024lorne/wp-admin/post.php?post=",

    pointsHaveDirections: true,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layerDefaults: {
      signage: {
        scaling: {
          minZoom: 10,
          opacityMinZoom: 8,
          opacityMaxZoom: 9,

          clusterOpacityMinZoom: 8,
          clusterOpacityMaxZoom: 9,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        scaling: {
          minZoom: 10,
          opacityMinZoom: 8,
          opacityMaxZoom: 9,

          clusterOpacityMinZoom: 8,
          clusterOpacityMaxZoom: 9,
          unclusterScale: 0.7
        },

        hasPopup: true,
        showLabels: false,
        hoverState: true,
        haveToolTip: true,
        styles: {
          unclustered_paint: { "circle-color": "#ff0000" },
          clustered_paint: { "circle-color": "#354d64" }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: {
          cluster: false,
          clusterMaxZoom: 10,
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    /*categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        title: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        title: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        title: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },
*/
    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-link",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "279px",
    drawButtonWidth: "435px",

    mediaButtonColor: "mediaButtonColor", //a6caa6
    filterLegendButtonColor: "filterLegendButtonColor", //a6caa6

    appButtonColor: "black",
    dialogActionButtonColor: "black",

    menuBarColor: "#001a5d",
    audioPlayColor: "c99975",
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerText: "#fcfbf1"
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
