<template>
  <center>
    <div class="mt-5">
      <div style="width:200px">
        <v-switch
          v-model="showDescriptions"
          label="Show Descriptions"
        ></v-switch>
      </div>
      <div
        fluid
        style="width:900px;max-width:100%;"
        id="ConferenceScheduleList"
      >
        <template v-for="(timeKey, timeIndex) in eventTimeKeys">
          <div :key="`timeRow_${timeKey}`">
            <v-card
              class="ma-0 eventTime"
              outlined
              tile
              color="eventHeaderBack"
            >
              <div class="eventTimeTitle  pa-2">
                {{ eventTimeKeysFormated[timeIndex] }}
              </div>
            </v-card>
            <div>
              <v-container class="eventListBack mb-0 pb-2">
                <v-row no-gutters class="mb-0 pb-0">
                  <v-col
                    v-for="event in eventsByTime[timeKey]"
                    :key="event.postId"
                    cols="12"
                    :md="eventTimeKeysWidths[timeIndex]"
                    class="mb-2"
                  >
                    <v-card
                      class="pa-2 pt-0 mx-1 eventItem"
                      outlined
                      tile
                      color="eventItemBack"
                    >
                      <div
                        class="eventTitle menuBarColor--text"
                        v-html="event.title"
                      ></div>

                      <a
                        v-if="
                          event.linkedMarkerId &&
                            sitesLookup[event.linkedMarkerId] &&
                            sitesLookup[event.linkedMarkerId].Point_Name
                        "
                        @click="
                          loadMarker(sitesLookup[event.linkedMarkerId].index)
                        "
                      >
                      </a>
                      <div
                        class="d-flex  flex-column align-startx
"
                      >
                        <div
                          class="eventValue"
                          v-if="
                            !(
                              event.linkedMarkerId &&
                              sitesLookup[event.linkedMarkerId] &&
                              sitesLookup[event.linkedMarkerId].Point_Name
                            )
                          "
                        >
                          no location {{ event.linkedMarkerId }}
                        </div>

                        <div
                          class="eventValue"
                          v-if="
                            event.linkedMarkerId &&
                              sitesLookup[event.linkedMarkerId] &&
                              sitesLookup[event.linkedMarkerId].Point_Name
                          "
                        >
                          <font class="subHeadingItem">location: </font
                          >{{ sitesLookup[event.linkedMarkerId].Point_Name }}
                        </div>
                        <EventPeopleSection :event="event" />
                        <div v-if="event.endTimeFormated" class="eventValue">
                          <font class="subHeadingItem">end: </font
                          >{{ event.endTimeFormated }}
                        </div>

                        <a
                          v-if="
                            event.linkedMarkerId &&
                              sitesLookup[event.linkedMarkerId] &&
                              sitesLookup[event.linkedMarkerId].Point_Name
                          "
                          @click="
                            loadMarker(sitesLookup[event.linkedMarkerId].index)
                          "
                        >
                          <v-btn
                            color="white"
                            raised
                            rounded
                            x-small
                            class="font-weight-bold"
                          >
                            Go To Location
                          </v-btn>
                        </a>
                      </div>
                      <div
                        v-if="showDescriptions && event.description"
                        class="eventValue eventDescription pt-2"
                      >
                        <font class="subHeadingItem" v-show="false"
                          >Description:
                        </font>

                        <div
                          style="color:rgb(87, 87, 87);margin-bottom: 10px;padding-bottom: 10px;xborder-bottom:1px solid grey;"
                          v-show="event.description"
                          v-html="event.description"
                        ></div>
                      </div>

                      <div v-if="isDevEnviroment">
                        <v-btn
                          @click="editWpItem(event.postId)"
                          depressed
                          class="ma-2"
                        >
                          <v-icon left>mdi-pen</v-icon>
                          Cust WP Edit
                        </v-btn>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </template>
      </div>
    </div>
  </center>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

import EventPeopleSection from "./EventPeopleSection.vue";

const moment = require("moment");
export default {
  name: "ConferenceScheduleList",
  components: {
    EventPeopleSection
  },
  data() {
    return { showDescriptions: false };
  },

  methods: {
    editWpItem(post_id) {
      //console.log("editWpItem", { post_id: properties.post_id });

      //let route = this.$router.resolve({ path: "/contact" });
      if (post_id) {
        window.open(
          `https://cms01.maptivateapp.com.au/vpelaconference2024lorne/wp-admin/post.php?post=${post_id}&action=edit`
        );
      }
    },

    loadMarker(index) {
      if (!isNaN(index)) {
        eventBus.$emit("nav_schedule_setOpenState", false);

        setTimeout(() => {
          eventBus.$emit("nav_map_flyToOpenPopUp", index);
        }, 700);
      }
    }
  },

  computed: {
    ...mapState({
      isDevEnviroment: state => state.navigation.isDevEnviroment,

      markerList: state => state.markers.markerList,
      eventsFormated: state => state.markers.eventsFormated,
      eventsByTime: state => state.markers.eventsByTime,
      eventsLookup: state => state.markers.eventsLookup
    }),

    eventTimeKeys() {
      let output = Object.keys(this.eventsByTime);
      output.sort();
      return output;
    },
    eventTimeKeysFormated() {
      return this.eventTimeKeys.map(startDateTime => {
        try {
          let temp = moment(startDateTime, "YYYY-MM-DD H:m:s");
          return temp.format("dddd h:mm a");
        } catch (e) {
          return startDateTime;
        }
      });
    },
    eventTimeKeysWidths() {
      let list = this.eventTimeKeys.map(time => {
        let output =
          this.eventsByTime[time].length > 12
            ? 1
            : 12 / this.eventsByTime[time].length;

        if (output < 4) {
          output = 4;
        }

        try {
          output = Math.round(output);
        } catch (error) {
          //do nothing;
        }

        return output;
      });

      return list;
    },

    sites() {
      return this.markerList.data.features;
    },
    sitesLookup() {
      let output = this.sites.map(row => {
        return row.properties;
      });

      let sitesLookup = {};
      output.forEach(row => {
        sitesLookup[row.post_id] = row;
      });

      if (sitesLookup) {
        //do nothingk
      }

      return sitesLookup;
    }
  }
};
</script>
<style>
#ConferenceScheduleList {
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  border: 1px solid #d7d7d7;
}
.eventDescription {
  text-align: left;
  xwidth: 300px;
  xmax-width: 80%;
}

.eventTime {
  background: #e3e3e3;
}

.eventItem {
}

.eventTitle {
  max-width: 400px;
  line-height: 1.2;
  padding: 5px 0;
  font-weight: 500;
}

.eventTitle,
.eventTimeTitle {
  font-size: larger;
}
.eventValue {
  font-size: smaller;
}
.subHeadingItem {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
