var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:(_vm.$vuetify.breakpoint.xsOnly
              ? {
                  'margin-right': ' 0px !important',
                  'margin-left': '0px !important',
                  'padding-right': '0px !important',
                  'min-width': '40px'
                }
              : {}),attrs:{"title":"Use google translate to translate text on this page.","color":"logoTextColor","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-google-translate ")]),_c('font',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xsOnly),expression:"!$vuetify.breakpoint.xsOnly"}]},[_vm._v(" Language")]),_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-list',{staticClass:"menuList"},[_vm._l((!_vm.showFullListAct ? _vm.countries : _vm.defaultCountries),function(country){return [_c('v-list-item',{key:country.title,attrs:{"title":country.title},on:{"click":function($event){return _vm.doTranslation(country.code)}}},[_c('v-list-item-title',[_c('font',{staticClass:"notranslate",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(country.title)+" ")]),(
                  _vm.getNativeName(country) &&
                    country.title == 'English' &&
                    _vm.currentCountryNotEnglish
                )?_c('font',[_vm._v(" - "+_vm._s(_vm.getNativeName(country))+" ")]):_vm._e(),(_vm.getNativeName(country) && country.title != 'English')?_c('font',{staticClass:"notranslate",attrs:{"translate":"no"}},[_vm._v(" - "+_vm._s(_vm.getNativeName(country))+" ")]):_vm._e()],1)],1)]}),(!_vm.showFullListAct)?_c('v-list-item',{on:{"click":function($event){return _vm.showMoreItems()}}},[_c('v-list-item-title',[_c('font',{staticClass:"notranslate",attrs:{"translate":"no"}},[_vm._v(" more ")]),(_vm.currentCountryNotEnglish)?_c('font',[_vm._v(" - more ")]):_vm._e()],1)],1):_vm._e()],2)],1)],1),_c('div',{attrs:{"id":"google_translate_element2"}},[_vm._t("default")],2),(
      !_vm.currentCountry ||
        (_vm.currentCountry &&
          _vm.currentCountry.code &&
          _vm.currentCountry.code != 'en|en')
    )?_c('div',[_c('v-btn',{style:(_vm.$vuetify.breakpoint.xsOnly
          ? {
              'margin-right': ' 0px !important',
              'margin-left': '0px !important',
              'padding-right': '0px !important',
              'padding-left': '0px !important',
              'min-width': '40px'
            }
          : {}),on:{"click":function($event){return _vm.resetTranslator()}}},[_c('font',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xsOnly),expression:"!$vuetify.breakpoint.xsOnly"}]},[_c('font',{staticClass:"notranslate",attrs:{"translate":"no"}},[_vm._v(" Reset ")]),(_vm.currentCountryNotEnglish)?_c('font',[_vm._v(" - Reset ")]):_vm._e()],1),_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }