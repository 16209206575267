<template>
  <div
    class="py-2"
    v-if="
      (event.convener && event.convener.length) ||
        (event.presenters && event.presenters.length) ||
        (event.panelist && event.panelist.length)
    "
  >
    <div class="eventValue" v-if="event.convener && event.convener.length">
      <font class="subHeadingItem">Convener: </font>

      <template v-for="(presenter, presenterKey) in event.convener">
        <EventPerson
          :key="
            `presenterWrapper_convener__${event.postId}_${presenter.postId}`
          "
          :presenterKey="presenterKey"
          :length="event.convener.length"
          :presenter="presenter"
          :event="event"
        />
      </template>
    </div>

    <div class="eventValue" v-if="event.presenters && event.presenters.length">
      <font class="subHeadingItem"
        >{{ event.presenters.length > 1 ? "Presenters" : "Presenter" }}:
      </font>

      <template v-for="(presenter, presenterKey) in event.presenters">
        <EventPerson
          :key="
            `presenterWrapper_convener__${event.postId}_${presenter.postId}`
          "
          :presenterKey="presenterKey"
          :length="event.presenters.length"
          :presenter="presenter"
          :event="event"
        />
      </template>
    </div>

    <div class="eventValue" v-if="event.panelist && event.panelist.length">
      <font class="subHeadingItem"
        >{{ event.panelist.length > 1 ? "Panelists" : "Panelist" }}:
      </font>

      <template v-for="(presenter, presenterKey) in event.panelist">
        <EventPerson
          :key="
            `presenterWrapper_panelist__${event.postId}_${presenter.postId}`
          "
          :presenterKey="presenterKey"
          :length="event.panelist.length"
          :presenter="presenter"
          :event="event"
        />
      </template>
    </div>
  </div>
</template>

<script>
import EventPerson from "./EventPerson.vue";

export default {
  name: "EventPeopleList",
  data() {
    return {};
  },
  props: {
    event: Object
  },
  methods: {},
  components: {
    EventPerson
  },
  computed: {}
};
</script>
<style></style>
