const turf = require("@turf/turf");

export default new (class {
  async drawPlane({ parentThis, map, appSettings }) {
    if (parentThis && map && appSettings) {
      //do nothing;
    }

    // San Francisco
    //const origin =[144.454, -38.118];
    //const destination =  [144.469, -38.027];

    //    const origin = [144.48270823001985, -37.939131418785074];
    //  const //[144.46480484806233, -38.05442628607689];

    const origin = [153.46589930016063, -28.101907224875433];
    const destination = [153.5128982347989, -28.175624292340125];
    // A simple line from origin to destination.
    const route = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [origin, destination]
          }
        }
      ]
    };
    const point = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: origin
          }
        }
      ]
    };

    const lineDistance = turf.length(route.features[0]);
    const arc = [];

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    const steps = 500;

    // Draw an arc between the `origin` & `destination` of the two points
    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
      const segment = turf.along(route.features[0], i);
      arc.push(segment.geometry.coordinates);
    }

    // Update the route with calculated arc coordinates
    route.features[0].geometry.coordinates = arc;

    // Used to increment the value of the point measurement against the route.
    let counter = 0;

    const zoomSettings = [
      "interpolate",
      ["linear"],
      ["zoom"],
      5,
      0.01,
      9,
      0.2,
      14,
      0.5,
      20,
      2,
      22,
      3
    ];

    // Add a source and layer displaying a point which will be animated in a circle.
    map.addSource("route", {
      type: "geojson",
      data: route
    });

    map.addSource("point", {
      type: "geojson",
      data: point
    });

    map.addLayer({
      id: "point",
      source: "point",
      type: "symbol",
      layout: {
        "icon-image": "383",
        "icon-size": zoomSettings,
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true
      }
    });
    var running = false;

    if (running) {
      //do ntohing;
    }

    function animate() {
      running = true;
      const start =
        route.features[0].geometry.coordinates[
          counter >= steps ? counter - 1 : counter
        ];
      const end =
        route.features[0].geometry.coordinates[
          counter >= steps ? counter : counter + 1
        ];

      if (!start || !end) {
        running = false;
        //document.getElementById("replaydisabled = false;

        setTimeout(() => {
          counter = 0;
          animate(counter);
        }, 3000);

        return;
      }
      // Update point geometry to a new position based on counter denoting
      // the index to access the arc
      point.features[0].geometry.coordinates =
        route.features[0].geometry.coordinates[counter];

      point.features[0].properties.bearing =
        90 + turf.bearing(turf.point(start), turf.point(end));

      // Update the source with this new data
      map.getSource("point").setData(point);

      // Request the next frame of animation as long as the end has not been reached
      if (counter < steps) {
        requestAnimationFrame(animate);
      }

      counter = counter + 1;
    }

    animate(counter);
  }
})();
