<template>
  <font
    :title="
      `${presenter.title} ${presenter.organization ? 'from ' : ''}${
        presenter.organization
      } `
    "
  >
    <b>{{ presenter.title }}</b
    >{{ presenter.qualifications ? ", " : "" }}{{ presenter.qualifications
    }}{{ presenter.organization ? ", " : "" }}{{ presenter.organization
    }}<font class="gap" v-show="length > presenterKey + 1">, </font>
  </font>
</template>

<script>
export default {
  name: "EventPersonItem",
  data() {
    return {};
  },
  props: {
    length: Number,
    presenterKey: Number,
    event: Object,
    presenter: Object,
    title: String
  },
  methods: {},

  computed: {}
};
</script>
<style></style>
