const targets = [
  /*
  {
    postId: "signage",
    taxonomies: ["signage_section"],
    sectionTaxonomies: "signage_section",
    listTaxonomies: "signage_section",
    geoData: true,
    forceMarkType: "signage"

  },*/
  {
    postId: "markers",
    taxonomies: ["markersection"],
    sectionTaxonomies: "markersection",
    listTaxonomies: "markersection",
    geoData: true
  },
  {
    postId: "artwork",
    taxonomies: ["artwork_section"],
    sectionTaxonomies: "artwork_section",
    listTaxonomies: "artwork_section",

    geoData: true,
    forceMarkType: "artwork"
  },
  {
    postId: "events",
    taxonomies: [],
    //sectionTaxonomies: ,
    //listTaxonomies: "artwork_section",

    geoData: false,
    isLinkedData: true
    //forceMarkType: "artwork"
  },
  {
    postId: "speakers",
    taxonomies: [],
    //sectionTaxonomies: ,
    //listTaxonomies: "artwork_section",

    geoData: false,
    isLinkedData: true
    //forceMarkType: "artwork"
  }
];

module.exports = {
  targets
};
